:root[game-name='souls']{
    --game-detail-pc-content1-download-bottom: 74;
    --game-detail-pc-content1-download-height: 62;
    --game-detail-mobile-content1-download-bottom: 260;
    --game-detail-mobile-content1-download-height: 240;
    --game-detail-mobile-content1-download-width: 2200;
    --game-detail-mobile-content1-download-item-width: 250;

    --game-detail-pc-content5-download-bottom: 330;
    --game-detail-pc-content5-download-height: 62;
    --game-detail-pc-content5-download-left: 1510;
    --game-detail-mobile-content5-download-bottom: 230;
    --game-detail-mobile-content5-download-height: 77;
    --game-detail-mobile-content5-download-width: 660;
    --game-detail-mobile-content5-download-left: 50;
    --game-detail-mobile-content5-download-item-width: 250;

    --game-detail-pc-content5-media-bottom: 175;
    --game-detail-pc-content5-media-height: 50;
    --game-detail-pc-content5-media-left: 1720;

    --game-detail-mobile-content5-media-bottom: 60;
    --game-detail-mobile-content5-media-height: 50;
    --game-detail-mobile-content5-media-left: 260;
}