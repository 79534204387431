.home-content-archero2-view {
    // height: 1212/768 * 100vw;
    position: relative;
    .new {
        position: absolute;
        display: block;
        top: 199 / 2560 * 100vw;
        left: 347 / 2560 * 100vw;
        @media screen and (max-width: 768px) {
            top: 45/768 * 100vw;
            left: 47/768*100vw;
        }
        img{
            width: 20/768 * 100vw;
            @media screen and (max-width: 768px) {
                width: 92/768 * 100vw;
            }
        }
    }
    .contents {
        @media screen and (min-width: 768px) {
            position: absolute;
            top: 241 / 2560 * 100vw;
            left: 330 / 2560 * 100vw;
        }
        @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            flex-direction: column;

            padding-left: 0;
            padding-top: 130/768 * 100vw ;
        }
        
        .title {
            position: relative;
            display: flex;
            align-items: flex-end;

            // margin: 10/768 * 100vw 0 8/768 * 100vw;

            white-space: pre-wrap;

            color: #ffffff;

            line-height: 1 !important;

            @media screen and (max-width: 768px) {
                align-items: center;
                flex-direction: column;
                // margin: 0/768 * 100vw 0 22/768 * 100vw;


                font-size: 50/768 * 100vw;
                line-height: 60/768 * 100vw;
            }

            .logo-wrapper {
                display: inline-block;
                overflow: hidden;
                position: relative;
                width: 476px;
                margin-left: -25px;

                @media screen and (max-width: 768px) {
                    width: 460/768 * 100vw;
                    margin-right: 0;
                }

                img {
                    display: block;
                    width: 100%;
                }
            }
        }

        .google-play-wrapper {
            width: 694px;
            margin-bottom: 36px;

            @media screen and (max-width: 768px) {
                width: 676/768 * 100vw;
                margin-bottom: 20/768 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }

        .desc {
            width: 700px;
            margin-bottom: 36px;

            white-space: pre-wrap;

            color: #ffffff;

            @media screen and (max-width: 768px) {
                width: 606/768 * 100vw;
                // margin-bottom: 60/768 * 100vw !important;

                text-align: center;

                font-size: 30/768 * 100vw;
                line-height: 45/768 * 100vw;
            }
        }

        .wrap{
            @media screen and (max-width: 768px) {
                text-align: center;
            }
        }
    }
}
.abutBtnIos{
    @media screen and (max-width: 768px) {
        display: none;
    }
}
.abutBtnA{
    margin: 0 20px;
    @media screen and (max-width: 768px) {
        display: none;
    }
}
.sss{
    padding-left: 2.1vw;
    padding-right: 2.1vw;
    @media screen and (max-width: 768px) {
        padding-left: 9.4vw;
        padding-right: 9.4vw;
    }
}
.classNone{
    opacity: 0;
}



.jump-image{
    width: 191px;
    height: 66px;
    vertical-align: middle;
    @media screen and (max-width: 1281px) {
        height: 38px;
    }

    @media screen and (max-width: 768px) {
        height: calc(33/768 * 100vw * 2 + 12/320 * 100vw);
    }
}
