.tools {
    display: flex;
    flex-direction: row;

    .downloadBtn {
        width: 191px;
        height: 66px;
        margin-right: 29px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .detailBtn {
        width: 191px;
        height: 66px;
        display: flex;
        background-color: #ED2B2A;
        border-radius: 10px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            width: 267 / 768 * 100vw;
            height: 99 / 768 * 100vw;
            border-radius: 15 / 768 * 100vw;
            margin-top: 40 / 768 * 100vw;
        }

        .detail-arrow {
            width: 12px;
            height: 15px;
            margin-right: 14px;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                width: 18 / 768 * 100vw;
                height: 22 / 768 * 100vw;
                margin-right: 13 / 768 * 100vw;
            }
        }

        .detail-label {
            font-family: 'Rubik', sans-serif;
            font-weight: 500;
            font-size: 19px;
            color: #FFFFFF;
            line-height: 23px;
            text-align: left;
            font-style: normal;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                font-size: 29 / 768 * 100vw;
                line-height: 34 / 768 * 100vw;
            }
        }
    }
}