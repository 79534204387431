.game-detail-souls{
    height: auto;
    a {
        cursor: pointer;
    }
    img{
        width: 100%;
        height: auto;
        display: block;
    }
    .view {
        position: relative;
        min-height: 1200/2560 * 100vw;
        @media screen and (max-width: 768px) {
            min-height: 1200/768 * 100vw;
        }


    }
    .view1{
        .downloadContent {
            position: absolute;
            bottom: calc(var(--game-detail-pc-content1-download-bottom)/2560*100vw);
            left: 50%;
            transform: translateX(-50%);
            height: calc(var(--game-detail-pc-content1-download-height)/2560*100vw);
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
                bottom: calc(var(--game-detail-mobile-content1-download-bottom)/2560*100vw);
                height: calc(var(--game-detail-mobile-content1-download-height)/2560*100vw);
                width: calc(var(--game-detail-mobile-content1-download-width)/2560*100vw);
            }

            .downloadItem {
                display: block;
                width: 185 / 2560 * 100vw;
                margin-right: 48 / 2560 * 100vw;
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    width: calc(var(--game-detail-mobile-content1-download-item-width)/768*100vw)
                }
            }
            .downloadItem:last-child {
                margin-right: 0
            }

        }
    }
    .view4 {
        .downloadContent {
            position: absolute;
            bottom: calc(var(--game-detail-pc-content4-download-bottom)/2560*100vw);
            left: calc(var(--game-detail-pc-content4-download-left)/2560*100vw);
            height: calc(var(--game-detail-pc-content4-download-height)/2560*100vw);
            display: flex;

            @media screen and (max-width: 768px) {
                bottom: calc(var(--game-detail-mobile-content4-download-bottom)/768*100vw);
                height: calc(var(--game-detail-mobile-content4-download-height)/768*100vw);
                width: calc(var(--game-detail-mobile-content4-download-width)/768*100vw);
                left: calc(var(--game-detail-mobile-content4-download-left)/768*100vw);
            }

            .downloadItem {
                display: block;
                width: 185 / 2560 * 100vw;
                margin-right: 45 / 2560 * 100vw;
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    width: 300 / 768 * 100vw;
                    margin-right: 35 / 768 * 100vw;
                }
            }
            .downloadItem:last-child {
                margin-right: 0
            }
        }
        .mediaContent {
            position: absolute;
            bottom: calc(var(--game-detail-pc-content4-media-bottom)/2560*100vw);
            left: calc(var(--game-detail-pc-content4-media-left)/2560*100vw);
            height: calc(var(--game-detail-pc-content4-media-height)/2560*100vw);
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 768px) {
                bottom: calc(var(--game-detail-mobile-content4-media-bottom)/768*100vw);
                height: calc(var(--game-detail-mobile-content4-media-height)/768*100vw);
                width: calc(var(--game-detail-mobile-content4-media-width)/768*100vw);
                left: calc(var(--game-detail-mobile-content4-media-left)/768*100vw);
            }

            .mediaItem {
                width: 50 / 2560 * 100vw;
                height: 50 / 2560 * 100vw;
                margin-right: 45 / 2560 * 100vw;
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    width: 50 / 768 * 100vw;
                    height: 50 / 768 * 100vw;
                    margin-right: 45 / 768 * 100vw;
                }
            }
        }
    }
    .view5{
        .downloadContent {
            position: absolute;
            bottom: calc(var(--game-detail-pc-content5-download-bottom)/2560*100vw);
            left: calc(var(--game-detail-pc-content5-download-left)/2560*100vw);
            height: calc(var(--game-detail-pc-content1-download-height)/2560*100vw);
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
                bottom: calc(var(--game-detail-mobile-content5-download-bottom)/768*100vw);
                height: calc(var(--game-detail-mobile-content5-download-height)/768*100vw);
                width: calc(var(--game-detail-mobile-content5-download-width)/768*100vw);
                left: calc(var(--game-detail-mobile-content5-download-left)/768*100vw);
            }

            .downloadItem {
                display: block;
                width: 185 / 2560 * 100vw;
                margin-right: 45 / 2560 * 100vw;
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    width: calc(var(--game-detail-mobile-content5-download-item-width)/768*100vw)
                }
            }
            .downloadItem:last-child {
                margin-right: 0
            }
        }
        .mediaContent {
            position: absolute;
            bottom: calc(var(--game-detail-pc-content5-media-bottom)/2560*100vw);
            left: calc(var(--game-detail-pc-content5-media-left)/2560*100vw);
            height: calc(var(--game-detail-pc-content1-media-height)/2560*100vw);
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 768px) {
                bottom: calc(var(--game-detail-mobile-content5-media-bottom)/768*100vw);
                height: calc(var(--game-detail-mobile-content5-media-height)/768*100vw);
                left: calc(var(--game-detail-mobile-content5-media-left)/768*100vw);
            }

            .mediaItem {
                width: 50 / 2560 * 100vw;
                height: 50 / 2560 * 100vw;
                margin-right: 45 / 2560 * 100vw;
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    width: 50 / 768 * 100vw;
                    height: 50 / 768 * 100vw;
                    margin-right: 45 / 768 * 100vw;
                }
            }
        }
        .Wrap{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 2200/2560 * 100vw;
            bottom: 0;
            height: 1030/2560 * 100vw;
            @media screen and (min-width: 768px) {
                height: 390/2560 * 100vw;
                width: 640/2560 * 100vw;
                left: 72%;
            }
            .downClass{
                height: 240/2560 * 100vw;
                width: 2200/2560 * 100vw;
                display: flex;
                justify-content: space-between;
                @media screen and (min-width: 768px) {
                    height: 60/2560 * 100vw;
                    width: 640/2560 * 100vw;
                }
                a {
                    display: block;
                    width: 32%;
                }
            }
            .mediaClass{
                height: 180/2560 * 100vw;
                display: flex;
                justify-content: space-between;
                margin-top: 400/2560 * 100vw;
                padding: 0 31.4%;
                @media screen and (min-width: 768px) {
                    height: 50/2560 * 100vw;
                    margin-top: 110/2560 * 100vw;
                }
                a {
                    display: block;
                    width: 21%;
                }
            } 
        }
    }
}
