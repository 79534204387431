:root[game-name='archero2']{
    --game-detail-pc-content1-download-bottom: 65;
    --game-detail-pc-content1-download-height: 62;
    --game-detail-mobile-content1-download-bottom: 260;
    --game-detail-mobile-content1-download-height: 240;
    --game-detail-mobile-content1-download-width: 1600;
    --game-detail-mobile-content1-download-item-width: 250;

    --game-detail-pc-content4-download-bottom: 405;
    --game-detail-pc-content4-download-height: 62;
    --game-detail-pc-content4-download-left: 250;

    --game-detail-mobile-content4-download-bottom: 260;
    --game-detail-mobile-content4-download-height: 77;
    --game-detail-mobile-content4-download-left: 150;
    --game-detail-mobile-content4-download-width: 450;

    --game-detail-pc-content4-media-bottom: 260;
    --game-detail-pc-content4-media-height: 50;
    --game-detail-pc-content4-media-left: 250;

    --game-detail-mobile-content4-media-bottom: 110;
    --game-detail-mobile-content4-media-height: 50;
    --game-detail-mobile-content4-media-left: 260;
}