.home-content-culture-view {
    .content {
        position: absolute;
        top: 280px;
        left: 1500px;

        @media screen and (max-width: 768px) {
            top: 140/768 * 100vw;
            left: 50%;

            transform: translateX(-50%);
            text-align: center;
        }

        .title {
            white-space: pre-wrap;

            color: white;
        }

        .desc {
            width: 800px;

            white-space: pre-wrap;

            color: white;

            @media screen and (max-width: 768px) {
                width: 253/320 * 100vw;
            }
        }
    }
}
