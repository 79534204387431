.home-content-publish-view {
    .content {
        position: absolute;
        top: 180px;
        left: 50%;

        width: 1060px;

        transform: translateX(-50%);
        text-align: center;

        @media screen and (max-width: 768px) {
            top: 60/320 * 100vw;

            width: 266/320 * 100vw;
        }

        .title {
            white-space: pre-wrap;

            color: #000000;
        }

        .desc {
            white-space: pre-wrap;

            color: #000000;
        }
    }
}
