.game-card-component {
    width: 100%;
    // margin-right: 50/2560*100vw;
    text-align: center;
    background-color: #ffffff;
    height: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
        position: relative;
    }

    @media screen and (max-width: 768px) {
        width: 265/320 * 100vw;
        // height: 1091/768 * 100vw;
    }

    .image-wrapper {
        // overflow: hidden;
        width: 530/2560 * 100vw;
        height: 401px;
        position: relative;
        // margin-bottom: 265/320 * 100% * 120/540 * 1/2px;
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 18/320 * 100vw;
            height: auto;
        }

        .background-container {
            display: block;
            width: 530/2560 * 100vw;
            height: 401px;
            border-top-left-radius: 20/2560 * 100vw;
            border-top-right-radius: 20/2560 * 100vw;
            overflow: hidden;

            @media screen and (max-width: 768px) {
                width: 100%;
                margin-bottom: 18/320 * 100vw;
                height: auto;
            }

            img {
                display: block;
                width: 100%;
                @media screen and (min-width: 768px) {
                    transform: scale(1);
                    transition: 0.5s ease-out;
                }
                
            }
        }

        .background-container:hover {
            img {
                @media screen and (min-width: 768px) {
                    transform: scale(1.03);
                    transition: 0.5s ease-out;
                }
            }
        }
    }

    .icon {
        width: 112/2560 * 100vw;
        margin: -47px auto 0;
        z-index: 9;
        position: relative;

        @media screen and (max-width: 768px) {
            display: none;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .title {
        color: #000000;
        // margin-bottom: 20px;
        margin-top:  12/2560 * 100vw;
        @media screen and (max-width: 768px) {
            // margin-bottom: 7/320 * 100vw;
            margin-top: 44/768 * 100vw;
        }
    }

    .desc {
        color: #a2b2c1;
        
        margin: 11px 0 22px;
        padding: 0 76px;
        line-height: 36px;
        display: flex;
        justify-content: center;
        @media screen and (min-width: 768px) {
            height: 116px;
        }
        @media screen and (max-width: 768px) {
            margin: 16/320 * 100vw 0;
            padding: 0 25/320 * 100vw;
            line-height: 39 / 768 * 100vw;
        }
    }

    .game-card-button {
        margin-bottom: 48px;

        @media screen and (max-width: 768px) {
            margin-bottom: 32/320 * 100vw;
        }
    }
}

.game-card-component:nth-child(3n+3) {
    margin-right: 0;
}
