.game-content-1-view {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 180/2560 * 100vw 400/2560 * 100vw 176/2560*100vw;
    // justify-content: space-between;
    background: #f6f6fb;
    .desc{
        color: #AAAABE;
        font-weight: 500;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 132/768 * 100vw 28/320 * 100vw 22/320 * 100vw;
        .game-card-animate-wrap{
            margin-top: 20px;
        }
        .game-card-animate-wrap:last-child{
            display: none;
        }
    }

    .game-card-animate-wrap {
        opacity: 1;
        width: 530/2560 * 100vw;
        margin-right: 50/2560*100vw;
        margin-bottom: 44px;
        @media screen and (min-width: 768px) {
            opacity: 0;
            animation: slide-up-then-back 0.5s forwards;
        }
    }

    .game-card-animate-wrap:nth-child(3n+3) {
        margin-right: 0;
    }

    .game-card-animate-wrap:last-child {
        height: 860px;
        cursor: default;
        .shadow-card {
            cursor: default;

            .image-wrapper,
            p,
            .game-card-button {
                display: none;
            }
            .shadow-card-inner {
                display: flex;
                justify-content: center;
                align-items: center;
                h3{
                    margin: 0;
                    color: #DBDEEA;
                }
            }
        }
    }

    each(range(1, 99, 1), {
        .game-card-animate-wrap:nth-child(@{index}) {
            animation-delay: @value * 0.2s;
        }
    });

}


@keyframes slide-up-then-back {
    0% {
      opacity: 0;
      transform: translateY(0.625rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
