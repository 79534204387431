.home-banner-view {
    @media screen and (max-width: 768px) {
        height: 1250/768 * 100vw;
    }

    .content {
        position: absolute;
        left: 50%;

        width: 1050px;
        margin-top: 268px;

        transform: translateX(-50%);

        @media screen and (max-width: 768px) {
            width: 265/320 * 100vw;
            margin-top: 358/768 * 100vw;
        }

        .logo-wrapper {
            width: 320px;
            margin: 0 auto 36px;

            @media screen and (max-width: 1281px) {
                width: 170px;
            }

            @media screen and (max-width: 768px) {
                width: 142/320 * 100vw;
                margin: 0 auto 16/320 * 100vw;
            }

            img {
                display: block;

                width: 100%;
            }
        }

        .desc {
            font-weight: 500;
            font-size: 23px;
            color: #94989E;
            line-height: 28px;
            letter-spacing: 9px;
            text-align: center;
            font-style: normal;
            text-transform: uppercase;
            margin-top: 36px;

            @media screen and (max-width: 768px) {
                font-size: 27/768 * 100vw;
                margin-top: 52/768 * 100vw;
                letter-spacing: 11 / 768 * 100vw;
                line-height: 32 / 768 * 100vw;
            }
        }
    }
}
