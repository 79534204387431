.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 191px;
    height: 66px;
    margin: 0;
    padding: 0;

    cursor: pointer;
    user-select: none;
    text-align: center;

    color: #ffffff;
    border: none;
    border-radius: 7px;
    background: #ED2B2A;

    &.button-disabled {
        pointer-events: none;

        background: #e9e9f2;

        .text {
            color: #a2b2c1;
        }

        .arrow-right {
            display: none;
        }
    }

    @media screen and (max-width: 1281px) {
        padding: 12px 14px;

        border-radius: 5px;
    }

    @media screen and (max-width: 768px) {
        width: 267 / 768 * 100vw;
        height: 99 / 768 * 100vw;
        padding: 33/768 * 100vw 34/768 * 100vw;
    }

    .arrow-right {
        display: inline-flex;

        margin-right: 8px;

        font-size: 16px;

        @media screen and (max-width: 1281px) {
            margin-right: 6px;

            font-size: 10px;
        }
    }

    .detail-arrow {
        width: 12px;
        height: 15px;
        margin-right: 14px;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            width: 18 / 768 * 100vw;
            height: 22 / 768 * 100vw;
            margin-right: 13 / 768 * 100vw;
        }
    }

    .text {
        font-family: 'Rubik', sans-serif;
        font-size: 19px;
        font-weight: 500;
        line-height: 1.2;

        @media screen and (max-width: 1281px) {
            font-size: 12px;
        }

        @media screen and (max-width: 768px) {
            font-size: 12/320 * 100vw;
        }
    }
}

a.button:active {
    color: #fff;
}

a.button:hover {
    color: #fff;
}
